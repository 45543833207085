<template>
    <BT-Blade-Item
        bladeName="client"
        :bladesData="bladesData"
        :canEdit="false"
        :canSave="false"
        cardWidth="1100"
        :loadingMsg="loadingMsg"
        navigation="clients"
        :refreshToggle="refreshToggle"
        title="Client">
        <template v-slot="{ data, item, save }">
            <v-container v-if="data.isNew">
                <v-row>
                    <v-col cols="6">
                        <BT-Field-String
                            v-model="item.companyName"
                            label="Company Name"
                            isEditing
                            :rules="requiredRules()" />
                            
                        <BT-Field-String
                            v-model="item.email"
                            label="Email Address"
                            isEditing
                            :rules="requiredRules()" />

                        <BT-Field-String
                            v-model="item.phoneNumber"
                            label="Phone Number"
                            isEditing />

                        <v-divider />

                        <BT-Field-Select
                            v-model="item.defaultTemplateID"
                            :isEditing="item.companyName != null && item.email != null"
                            itemText="templateName"
                            label="Copy Template"
                            navigation="client-templates" />

                    </v-col>

                    <v-col cols="6">
                        <BT-Field-String
                            label="Address Line One"
                            v-model="item.addressLineOne"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Street Number"
                            v-model="item.streetNumber"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Street Name"
                            v-model="item.streetName"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Suburb"
                            v-model="item.suburb"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="State"
                            v-model="item.state"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Postcode"
                            v-model="item.postcode"
                            :isEditing="item.companyName != null && item.email != null" />
                    </v-col>
                </v-row>
                <v-divider />
                <v-row>
                    <v-col cols="6">
                        <v-list-item>
                            <v-list-item-content>
                                <v-btn class="primary" :disabled="item.suburb == null && item.state == null && item.postcode == null" @click="validate(item)">
                                    Find Address Coordinates
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>

                        <BT-Field-String
                            label="Latitude"
                            v-model="item.lat"
                            isEditing />

                        <BT-Field-String
                            label="Longitude"
                            v-model="item.lng"
                            isEditing />
                    </v-col>
                    <v-col cols="6">
                        <GmapMap
                            v-if="item.lat != null && item.lng != null"
                            ref="locMap"
                            :center="centerPosition"
                            :zoom="7"
                            style="width: 100%; height: 250px;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">
                            <gmap-marker v-if="position != null" :position="position" />
                        </GmapMap>
                    </v-col>
                </v-row>
                <v-divider />
                <v-btn v-if="item.lat != null && item.lng != null" class="primary" @click="create(item)">
                    Create
                </v-btn>
                <BT-Snack v-model="msg" />
            </v-container>
            <v-container v-else>
                <BT-Entity
                    v-if="item.buyer.isManagedByAnotherAccount"
                    ignoreID
                    navigation="managed-company-details"
                    :proxyID="item.buyerID"
                    single>
                    <template v-slot="{ item, data, save }">
                        <v-row>
                            <v-col cols="12" sm="6" class="text-center">
                                <BT-Image-Edit
                                    v-if="data.isEditing"
                                    :canEdit="data.isEditing"
                                    :height="150"
                                    :id="item.id"
                                    justify-center align-center
                                    navigation="customer-image"
                                    placeholder="mdi-account-box"
                                    :src="companyLogoURL(item.id) + '?' + cacheData"
                                    @uploaded="cacheData = new Date().getTime()"
                                    :width="150" />
                                <v-img
                                    v-else
                                    height="150"
                                    :src="companyLogoURL(item.id)"
                                    width="150">
                                    <template v-slot:placeholder>
                                        <v-icon size="150" color="primary">mdi-account-box</v-icon>
                                    </template>
                                </v-img>

                            </v-col>
                            <v-col cols="12" sm="6">
                                <BT-Field-String
                                    v-model="item.companyName"
                                    label="Company Name"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />
                                    
                                <BT-Field-String
                                    v-model="item.primaryEmail"
                                    label="Primary Email Address"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />

                                <BT-Field-String
                                    v-model="item.phoneNumber"
                                    label="Phone Number"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-String
                                    v-model="item.website"
                                    label="Website"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-Select
                                    label="Default Timezone"
                                    v-model="item.defaultTimeZone"
                                    :items="timeZones"
                                    :isEditing="data.isEditing"
                                    :rules="requiredRules()" />

                            </v-col>
                        </v-row>
                        <v-slide-y-transition hide-on-leave>
                            <v-row v-if="data.isChanged" class="mt-0">
                                <v-btn v-if="data.isChanged" @click="save" block class="primary mt-5">
                                    <v-icon left>mdi-content-save</v-icon>Save
                                </v-btn>
                            </v-row>
                        </v-slide-y-transition>
                    </template>
                </BT-Entity>
                <v-row v-else>
                    <v-col cols="6" class="text-center">
                        <v-img
                            class="mx-auto"
                            height="150"
                            :src="companyLogoURL(item.buyerID)"
                            width="150">
                            <template v-slot:placeholder>
                                <v-icon size="150" color="primary">mdi-account-box</v-icon>
                            </template>
                        </v-img>
                    </v-col>
                    <v-col cols="6">
                        <BT-Field-String
                            v-model="item.buyer.companyName"
                            label="Company Name"
                            :rules="requiredRules()" />

                        <BT-Field-String
                            v-model="item.buyer.primaryEmail"
                            label="Email Address" />

                    </v-col>
                </v-row>

                <v-divider class="my-1" />

                <v-row v-if="item != null">
                    <v-col cols="1">
                        <v-list width="56">
                            <BT-Menu
                                v-if="item.buyer.isManagedByAnotherAccount"
                                icon="mdi-map-marker-multiple"
                                :item="item"
                                title="Locations">
                                <template>
                                    <BT-Blade-Items
                                        addBladeName="location"
                                        canAdd
                                        hideBackButton
                                        hideHeader
                                        navigation="locations"
                                        :proxyID="item.buyerID"
                                        showList>
                                        <template v-slot:listItem="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item | toFamiliarLocationLine }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </BT-Blade-Items>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                icon="mdi-currency-usd"
                                :item="item"
                                navigation="clients-invoicing"
                                title="Finance">
                                <template v-slot="{ data, item }">
                                    <BT-Entity
                                        inline
                                        :itemValue="item.id"
                                        navigation="clients-invoicing"
                                        single>
                                        <template v-slot="invSettings">
                                            <BT-Field-Select
                                                @change="invSettings.save"
                                                v-model="invSettings.item.priceTierID"
                                                itemText="priceTierName"
                                                label="Price Tier"
                                                navigation="courier-price-tiers"
                                                :isEditing="data.isEditing" />
                                            
                                            <BT-Field-Select
                                                @change="invSettings.save"
                                                v-model="invSettings.item.paymentTermsID"
                                                itemText="paymentTermsName"
                                                label="Payment Terms"
                                                navigation="courier-payment-terms"
                                                :isEditing="data.isEditing" />

                                            <BT-Field-Toggle-Trigger
                                                @change="invSettings.save"
                                                v-model="invSettings.item.invoiceGenerationTrigger"
                                                :isEditing="data.isEditing"
                                                label="Invoice Generation" />
                                        </template>
                                    </BT-Entity>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                icon="mdi-arrow-decision"
                                :itemValue="item.id"
                                navigation="clients-fulfilling"
                                title="3PL">
                                <template v-slot="{ item }">
                                    <BT-Entity
                                        inline
                                        :itemValue="item.id"
                                        navigation="clients-fulfilling"
                                        single>
                                        <template v-slot="fSettings">
                                            <BT-Field-Switch
                                                @change="fSettings.save"
                                                v-model="fSettings.item.clientCanRequestFulfillment"
                                                label="3PL Ordering"
                                                isEditing
                                                key="1" />

                                            <BT-Field-Toggle-Trigger
                                                @change="fSettings.save"
                                                v-model="fSettings.item.fulfillerApprovalTrigger"
                                                :disabled="!item.clientCanRequestFulfillment"
                                                isEditing
                                                label="Approve Order Fulfillment Requests" />

                                            <v-btn
                                                class="ma-1"
                                                :class="fSettings.item.isSuspended ? 'success' : 'error'"
                                                block
                                                @click="toggleSuspension(fSettings)"
                                                :disabled="!item.clientCanRequestFulfillment">
                                                {{ fSettings.item.isSuspended ? 'Unsuspend Account' : 'Suspend Account' }}
                                            </v-btn>
                                        </template>
                                    </BT-Entity>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                icon="mdi-timeline-clock"
                                :item="item"
                                navigation="clients"
                                title="Automation">
                                <template v-slot="{ item }">
                                    <v-timeline align-top dense>
                                        <v-timeline-item left>
                                            <template v-slot:default>
                                                <v-list-item dense>
                                                    <v-list-item-action>
                                                        <BT-Toggle-Trigger-Icon v-model="item.courierRequestApprovalTrigger" />
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Approve New Courier Orders</v-list-item-title>
                                                        <p><v-list-item-subtitle>Approve any new orders that this client places.</v-list-item-subtitle></p>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item dense>
                                                    <v-list-item-action>
                                                        <BT-Toggle-Trigger-Icon v-model="item.routePlanningTrigger" />
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Route Planning</v-list-item-title>
                                                        <p><v-list-item-subtitle>Plan what route will be taken for new courier orders.</v-list-item-subtitle></p>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item dense>
                                                    <v-list-item-action>
                                                        <BT-Toggle-Trigger-Icon v-model="item.routeMovementTrigger" />
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Movement Planning</v-list-item-title>
                                                        <p><v-list-item-subtitle>Plan what movements will be taken for new routes.</v-list-item-subtitle></p>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item dense>
                                                    <v-list-item-action>
                                                        <BT-Toggle-Trigger-Icon v-model="item.courierOrderBillTrigger" />
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Bill for completed courier orders</v-list-item-title>
                                                        <p><v-list-item-subtitle>Create new billable items for when courier orders are completed.</v-list-item-subtitle></p>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-timeline-item>
                                    </v-timeline>
                                </template>
                            </BT-Menu>
                        </v-list>
                    </v-col>
                    <v-col cols="11">
                        <v-row class="mt-2">
                            <v-spacer />

                            <BT-Sidebar-External-Links
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.id"
                                label="Integrate"
                                navigation="clients"
                                small
                                syncNavigation="client-syncing" />

                            <BT-Sidebar-Live-Links
                                v-if="item.buyer.isManagedByAnotherAccount"
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.buyerID"
                                label="Go Live"
                                rightIcon="mdi-account-multiple-check"
                                small
                                sidebarLabel="Links" />
                            <div v-else class="mx-2 px-2 subtitle-1">
                                <v-icon left>mdi-account</v-icon>Live
                            </div>
                        </v-row>
                        
                        <v-row>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    addBladeName="client-order"
                                    bladeName="client-orders"
                                    :getNewBladeData="() => { return { buyerID: item.buyerID } }"
                                    :headers="[
                                        { text: 'CO#', value: 'courierOrderNumber', subtitle: 4, prefix: 'CO# ' },
                                        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth' }, 
                                        { text: 'Departure From', value: 'departureLocation', display: true, subtitle: 3, prefix: 'From: ' },
                                        { text: 'Destination', value: 'destinationLocation', display: true, subtitle: 2, prefix: 'To: ' },
                                        { text: 'Status', value: 'id', display: true, subtitle: 5, prefix: 'Status: ' }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="5"
                                    navigation="client-orders"
                                    :params="{ buyerID: item.buyerID }"
                                    showTable
                                    title="Courier Orders">
                                    <template v-slot:id="{ item }">
                                        <span v-if="item.fulfilledOn != null">Fulfilled</span>
                                        <span v-else-if="item.isBilled">Billed</span>
                                        <span v-else-if="item.isPlanned">Planned</span>
                                        <span v-else-if="item.isApproved === true">Approved</span>
                                        <span v-else-if="item.isApproved === false">Rejected</span>
                                        <span v-else-if="item.isApproved == null">Unresponded</span>
                                    </template>
                                </BT-Blade-Items>
                            </v-col>
                        </v-row>
                        <v-divider class="my-5" />
                        <v-row>
                            <v-col cols="12">
                                <!-- <BT-Blade-Items
                                    addBladeName="customer-invoice"
                                    canSearchLocal
                                    :headers="[
                                        { text: 'INV#', value: 'invoiceNumber', searchable: true },
                                        { text: 'Due Date', value: 'dueOn', textFilter: 'toDayMonth', subtitle: '1', prefix: 'Due: ' },
                                        { text: 'Status', value: 'status', display: true, subtitle: 2 },
                                        { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency', subtitle: 3 }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="3"
                                    :itemProperties="['ID','DueOn','InvoiceNumber','IsPaid','AmountPaid','AmountTotal']"
                                    navigation="customer-invoices"
                                    :params="{ customerIDs: item.buyerID }"
                                    showTable
                                    title="Invoices">
                                    <template v-slot:status="{ item }">
                                        <span v-if="item.isPaid">Paid</span>
                                        <span v-else-if="item.amountPaid > 0">Partially Paid</span>
                                        <span v-else>Unpaid</span>
                                    </template>
                                </BT-Blade-Items> -->
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Customer-Blade',
    components: {
        // BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        // BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        // BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        BTSidebarLiveLinks: () => import('~components/BT-Sidebar-Live-Links.vue'),
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        // BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    props: {
        bladesData: null
    },
    data: function() {
        return {
            cacheData: new Date().getTime(),
            geocoder: null,
            loadingMsg: null,
            msg: null,
            panelV: null,
            position: null,
            refreshToggle: false,
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }
    },
    computed: {
        //google: getGoogleMapsAPI,
        centerPosition() {
            return this.position != null ? this.position : { lat: -38, lng: 144 };
        },
        timeZones() {
            return moment.tz.names();
        }
    },
    methods: {
        repositionMarker(location) {
            if (location.lat != null && location.lng != null) {
                this.position = {
                    lat: location.lat,
                    lng: location.lng
                }
            }
            else {
                this.position = null;
            }
        },
        async create(item) {
            this.loadingMsg = `Creating ${item.companyName}`;

             var data = {
                subscriptionCode: 'CLIENTFREE',
                companies: [item]
            };

            try {
                await this.$BlitzIt.api.post('new-clients', data, null, '/PostClients');

                this.msg = 'Done';
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async deletePointer(pointer) {
            try {
                pointer.isLoading = true;
                await this.$BlitzIt.store.delete('journey-pointers', pointer.id, null);
                pointer.id = null;
                pointer.rowVersion = null;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async deleteRoutePointer(routePointer) {
            try {
                routePointer.isLoading = true;
                await this.$BlitzIt.store.delete('route-pointers', routePointer.id, null);
                routePointer.id = null;
                routePointer.rowVersion = null;
                this.$BlitzIt.tracker.reset(routePointer);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                routePointer.isLoading = false;
            }
        },
        async deleteSupplyPointer(pointer) {
            try {
                pointer.isLoading = true;
                await this.$BlitzIt.store.delete('supply-pointers', pointer.id, null);
                pointer.id = null;
                pointer.rowVersion = null;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async savePointer(pointer) {
            try {
                pointer.isLoading = true;
                var res = null;
                if (pointer.id == null) {
                    res = await this.$BlitzIt.store.post('journey-pointers', pointer, null, null);
                }
                else {
                    res = await this.$BlitzIt.store.patch('journey-pointers', pointer, null);
                }
                
                pointer.id = res.id;

                pointer.rowVersion = res.rowVersion;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch(err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async saveRoutePointer(routePointer) {
            try {
                routePointer.isLoading = true;
                var res = null;
                if (routePointer.id == null) {
                    res = await this.$BlitzIt.store.post('route-pointers', routePointer, null, null);
                }
                else {
                    res = await this.$BlitzIt.store.patch('route-pointers', routePointer, null);
                }
                
                routePointer.id = res.id;

                routePointer.rowVersion = res.rowVersion;
                this.$BlitzIt.tracker.reset(routePointer);
            }
            catch(err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                routePointer.isLoading = false;
            }
            
        },
        async saveSupplyPointer(pointer) {
            try {
                pointer.isLoading = true;
                var res = null;
                if (pointer.id == null) {
                    res = await this.$BlitzIt.store.post('supply-pointers', pointer, null, null);
                }
                else {
                    res = await this.$BlitzIt.store.patch('supply-pointers', pointer, null);
                }
                
                pointer.id = res.id;

                pointer.rowVersion = res.rowVersion;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch(err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        async pullJourneyPointers(templates, agr) {
            var locationRes = await this.$BlitzIt.store.get('suppliers', agr.id, null, false, null, '/Locations/Locations');
            var locations = locationRes.possibleLocations;
            var res = [];

            if (this.isLengthyArray(locations)) {
                var locationIDs = locations.map(x => x.id);

                var pointers = await this.$BlitzIt.store.getAll('journey-pointers', { includeDetails: true, locationIDs: locationIDs.toString()}, false, null, null);

                templates.forEach(t => {
                    locations.forEach(l => {
                        var p = pointers.find(p => p.locationID == l.id && p.journeyTemplateID == t.id);
                        if (p == null) {
                            p = {
                                id: null,
                                rowVersion: null,
                                pointerName: null,
                                boundary: null,
                                weekdays: 'Always',
                                journeyTemplateID: t.id,
                                locationID: l.id,
                                location: l
                            };
                        }

                        this.$BlitzIt.tracker.track(p, {
                            trackProps: ['id', 'rowVersion', 'weekdays'],
                            assignIgnored: { journeyTemplate: t, isLoading: false }
                        });

                        res.push(p);
                    });
                });
            }

            return res;
        },
        async pullRoutes(routes, agr) {
            var locationRes = await this.$BlitzIt.store.get('suppliers', agr.id, null, false, null, '/Locations/Locations');
            var customerLocations = locationRes.possibleLocations;
            var res = [];

            if (this.isLengthyArray(customerLocations)) {
                var locationIDs = customerLocations.map(x => x.id);

                var pointers = await this.$BlitzIt.store.getAll('route-pointers', { includeDetails: true, locationIDs: locationIDs.toString()}, false, null, null);

                routes.forEach(route => {
                    customerLocations.forEach(custLocation => {
                        var ePointer = pointers.find(p => p.courierRouteID == route.id && p.locationID == custLocation.id);
                        
                        if (ePointer == null) {
                            ePointer = {
                                id: null,
                                isDestination: true,
                                rowVersion: null,
                                pointerName: agr.buyer.companyName,
                                boundary: null,
                                weekdays: 'Always',
                                locationID: custLocation.id,
                                location: custLocation,
                                courierRouteID: route.id
                            };
                        }

                        this.$BlitzIt.tracker.track(ePointer, {
                            trackProps: ['id', 'rowVersion', 'weekdays'],
                            assignIgnored: { routeName: route.routeName, isLoading: false }
                        });

                        res.push(ePointer);
                    });
                });
            }

            return res;
        },
        async pullSupplyPointers(locations, agr) {
            var locationRes = await this.$BlitzIt.store.get('suppliers', agr.id, null, false, null, '/Locations/Locations');
            var customerLocations = locationRes.possibleLocations;
            var res = [];

            if (this.isLengthyArray(customerLocations)) {
                var locationIDs = customerLocations.map(x => x.id);

                var pointers = await this.$BlitzIt.store.getAll('supply-pointers', { includeDetails: true, destinationLocationIDs: locationIDs.toString()}, false, null, null);

                locations.forEach(t => {
                    customerLocations.forEach(l => {
                        var p = pointers.find(p => p.destinationLocationID == l.id && p.locationID == t.id);
                        if (p == null) {
                            p = {
                                id: null,
                                rowVersion: null,
                                pointerName: agr.buyer.companyName,
                                boundary: null,
                                weekdays: 'Always',
                                locationID: t.id,
                                location: t,
                                destinationLocationID: l.id,
                            };
                        }

                        this.$BlitzIt.tracker.track(p, {
                            trackProps: ['id', 'rowVersion', 'weekdays'],
                            assignIgnored: { destinationLocation: l, isLoading: false }
                        });

                        res.push(p);
                    });
                });
            }

            return res;
        },
        async toggleSuspension(fSettings) {
            fSettings.item.isSuspended = !fSettings.item.isSuspended
            await fSettings.save();
        },
        async validate(item) {
            try {
                this.loadingMsg = 'Validating Address';
            
                var add = getLocationLine(item, true);
                
                await this.$gmapApiPromiseLazy();
                var google = getGoogleMapsAPI();

                if (this.geocoder == null) {
                    //await googleMapsApiInitializer({ key: process.env.VUE_APP_GOOGLE_KEY }, false);
                    this.geocoder = new google.maps.Geocoder();
                }

                var results = await this.geocoder.geocode({ address: add });

                if (results.results.length > 1) {
                    this.msg = 'Multiple Options Found';
                }
                else if (results.results.length == 1) {
                    item.lat = results.results[0].geometry.location.lat();
                    item.lng = results.results[0].geometry.location.lng();
                    this.repositionMarker(item);
                }
                else {
                    this.msg = 'Address Not Found';
                }
            }
            catch (err) {
                this.msg = 'Failed';
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>